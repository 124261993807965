.mask-gradient-right {
  mask-image: linear-gradient(70deg, rgba(0, 0, 0, 1), transparent 50%);
}

.mask-gradient-left {
  mask-image: linear-gradient(-100deg, rgba(0, 0, 0, 1), transparent 50%);
}

html {
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
}
